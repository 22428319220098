<template>
  <div id="app">
   <Production />
  </div>
</template>

<script>

import Production from './components/production.vue'

export default {
  name: 'App',

  components: {
    Production,
}
}
</script>

<style lang="scss">


</style>
